import React from 'react'
import styled from 'styled-components'

import { Editable } from 'components/blocks/deprecated/Editable'
import {
  CalculatorPricingListStoryblok,
  PricingListStoryblok,
  PricingSectionTabContentTableHeaderStoryblok,
  PricingSectionTabContentTableStoryblok,
} from 'lib/storyblok/types'

import { CalculatorPricingList } from './CalculatorPricingList'
import { PricingList } from './PricingList'
import { PricingTableHeader } from './PricingTableHeader'

const isCalculatorPricingList = (
  blok: CalculatorPricingListStoryblok | PricingListStoryblok
): blok is CalculatorPricingListStoryblok => {
  return blok.component === 'calculator-pricing-list'
}

export const getCurrency = (
  currency: PricingSectionTabContentTableHeaderStoryblok['currency']
): string => {
  switch (currency) {
    case 'pounds':
      return 'GBP'
    case 'euro':
      return 'EUR'
    case 'złoty':
      return 'PLN'
    default:
      return 'GBP'
  }
}

export const getLocale = (currency: string): string => {
  switch (currency) {
    case 'pounds':
      return 'en-GB'
    case 'euro':
      return 'en-GB'
    case 'złoty':
      return 'pl-PL'
    default:
      return 'en-GB'
  }
}

type Props = {
  data: PricingSectionTabContentTableStoryblok
}

export const PricingTable: React.FC<Props> = ({ data }) => {
  const { header, content } = data

  return (
    <Container>
      {header?.length && header?.[0] && (
        <PricingTableHeader header={header[0]} />
      )}

      {content?.length && (
        <TableContent>
          {content.map((pricingList) => (
            <Editable block={pricingList} key={pricingList._uid}>
              {isCalculatorPricingList(pricingList) ? (
                <CalculatorPricingList
                  data={pricingList}
                  locale={getLocale(header?.[0].currency || 'en-GB')}
                  currency={getCurrency(header?.[0].currency || 'pounds')}
                />
              ) : (
                <PricingList
                  data={pricingList}
                  currency={getCurrency(header?.[0].currency || 'pounds')}
                  locale={getLocale(header?.[0].currency || 'en-GB')}
                />
              )}
            </Editable>
          ))}
        </TableContent>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

const TableContent = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`
