import React from 'react'
import styled from 'styled-components'

import { Editable } from 'components/blocks/deprecated/Editable'
import { Tag } from 'components/ui'
import { Text } from 'components/ui/deprecated/Text'
import {
  PricingListItemValueStoryblok,
  PricingListStoryblok,
} from 'lib/storyblok/types'
import { addAlphaToColor, dashedLine } from 'lib/style'
import { textByLine } from 'lib/utils/content'
import { formatPrice } from 'lib/utils/price'
import { cn } from 'lib/utils/tailwind'

type Props = {
  data: PricingListStoryblok
  currency: string
  locale: string
}

export const PricingListHeader: React.FC<{
  tag?: string
  description?: string
}> = ({ tag, description }) => (
  <StyledPricingListHeader>
    {tag && <Tag>{tag}</Tag>}

    {description &&
      textByLine(description, (part) => (
        <Text as="p" variant="twenty">
          {part}
        </Text>
      ))}
  </StyledPricingListHeader>
)

export const PricingRowInfo: React.FC<{ name: string; description?: string }> =
  ({ name, description }) => (
    <StyledPricingRowInfo>
      <Text as="p" variant="twenty/regular">
        {name}
      </Text>
      {description && (
        <Text as="p" variant="twenty" className="opacity-80">
          {description}
        </Text>
      )}
    </StyledPricingRowInfo>
  )

export const PricingRowValue: React.FC<{
  rowValue: PricingListItemValueStoryblok
  locale: string
  currency: string
}> = ({ rowValue, locale, currency }) => {
  const { _uid, value } = rowValue

  return (
    <Editable block={rowValue} key={_uid}>
      <StyledPricingRowValue
        as="p"
        variant="twenty/regular"
        className={cn(value ? 'opacity-100' : 'opacity-80')}
      >
        {value && +value > 0 ? formatPrice(locale, value, currency, 0, 0) : '–'}
      </StyledPricingRowValue>
    </Editable>
  )
}

export const PricingList: React.FC<Props> = ({ data, currency, locale }) => {
  const { _uid, tag, rows } = data

  return (
    <Editable block={data} key={_uid}>
      <Container>
        <PricingListHeader tag={tag} />

        <PricingListRows>
          {rows?.map((row) => {
            const { _uid, name, description, values } = row

            return (
              <Editable block={row} key={_uid}>
                <PricingRow>
                  {name && (
                    <PricingRowInfo name={name} description={description} />
                  )}

                  <PricingRowValues>
                    {values?.map((rowValue) => (
                      <PricingRowValue
                        rowValue={rowValue}
                        locale={locale}
                        currency={currency}
                      />
                    ))}
                  </PricingRowValues>
                </PricingRow>
              </Editable>
            )
          })}
        </PricingListRows>
      </Container>
    </Editable>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const PricingListRows = styled.div`
  display: flex;
  flex-direction: column;
`

const PricingRow = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 0;
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.subtle, 40))};

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledPricingListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: 670px;
`

const StyledPricingRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  max-width: 584px;
`

const PricingRowValues = styled.div`
  display: flex;
  gap: 0.5rem;

  ${({ theme }) => theme.media.lg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
`

const StyledPricingRowValue = styled(Text)`
  display: flex;
  min-width: 68px;

  ${({ theme }) => theme.media.lg} {
    min-width: 78px;
  }
`
