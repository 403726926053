import { storyblokEditable } from '@storyblok/react'

import { Button, Link, Tag } from 'components/ui'
import { getValidIcon } from 'components/ui/Icon'
import {
  getValidIllustratedIcon,
  IllustratedIcon,
} from 'components/ui/IllustratedIcon'
import { ClinicPricingHeroStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink, textByLine } from 'lib/utils/content'
import { formatPrice } from 'lib/utils/price'

import { StoryblokImage } from '../StoryblokImage'
import { getCurrency, getLocale } from './PricingSection/PricingTable'

type Props = {
  block: ClinicPricingHeroStoryblok
}

export const ClinicPricingHero = ({ block }: Props): JSX.Element => {
  const { tag, title, description, link, image, cards, currency } = block

  return (
    <div
      data-theme="dark"
      className="px-5 w-full bg-background text-foreground"
      {...storyblokEditable(block)}
    >
      <div className="max-w-screen-desktop mx-auto px-0 md:px-20">
        <div className="flex flex-col lg:flex-row justify-between py-10 lg:py-15">
          <div className="flex flex-col pb-8 lg:pb-0 gap-6 max-w-[413px]">
            <div>
              {tag && <Tag>{tag}</Tag>}
              {title && (
                <h1 className="text-title-large font-regular mt-4">{title}</h1>
              )}
            </div>
            <div className="flex flex-col gap-8 md:gap-5">
              {description &&
                textByLine(description, (part) => (
                  <p className="text-twenty">{part}</p>
                ))}

              {link && link[0]?.label && link[0]?.link && (
                <Button
                  as="a"
                  variant="secondary"
                  href={getAnchorFromCmsLink(link[0].link).href}
                  target={getAnchorFromCmsLink(link[0].link).target}
                  rel={getAnchorFromCmsLink(link[0].link).rel}
                  icon={getValidIcon(link[0].icon)}
                >
                  {link[0].label}
                </Button>
              )}
            </div>
          </div>

          {image.filename && (
            <div className="relative w-full h-60 overflow-hidden rounded-tr-12 rounded-bl-12 md:h-[420px] md:max-w-[630px]">
              <StoryblokImage
                fill
                priority
                width={undefined}
                height={undefined}
                asset={image}
                className="object-cover"
              />
            </div>
          )}
        </div>

        <div className="h-full grid gap-5 pb-10 md:pb-15 grid-cols-1 lg:grid-cols-2 xl:grid-cols-[repeat(4,305px)]">
          {cards.map((card) => {
            const { _uid, icon, title, price, services, description, cta } =
              card

            const illustratedIcon = icon?.[0]
              ? getValidIllustratedIcon(icon[0].icon)
              : null

            return (
              <div
                key={_uid}
                className="flex flex-col justify-between gap-10 p-6 rounded-5 w-full min-h-full bg-background-dark lg:gap-15"
                {...storyblokEditable(card)}
              >
                <div className="flex flex-col gap-5">
                  {illustratedIcon && (
                    <IllustratedIcon icon={illustratedIcon} />
                  )}
                  <div>
                    <p className="text-twenty">{title}</p>
                    {price && (
                      <p className="text-title-medium">
                        {formatPrice(
                          getLocale(currency || 'en-GB'),
                          price || 0,
                          getCurrency(currency || 'pounds'),
                          0,
                          0
                        )}
                      </p>
                    )}
                    {description && (
                      <p className="text-eighteen mt-3">{description}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-2.5">
                    {services &&
                      services.map((service) => {
                        const { _uid, name, price } = service
                        return (
                          <div
                            key={_uid}
                            className="flex justify-between items-center gap-5"
                            {...storyblokEditable(service)}
                          >
                            <p className="opacity-80 text-twenty">{name}</p>
                            <p className="h-[29px] w-[78px] flex font- items-center justify-center px-3 pt-1 pb-1.25 rounded-2 bg-foreground/5 text-sixteen font-regular">
                              {formatPrice(
                                getLocale(currency || 'en-GB'),
                                price || 0,
                                getCurrency(currency || 'pounds'),
                                0,
                                0
                              )}
                            </p>
                          </div>
                        )
                      })}
                  </div>

                  {cta &&
                    cta?.[0] &&
                    cta[0]?.link &&
                    (() => {
                      const { label, link } = cta[0]
                      const { href, rel, target } = getAnchorFromCmsLink(link)
                      return (
                        <Link arrow rel={rel} href={href} target={target}>
                          {label}
                        </Link>
                      )
                    })()}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
