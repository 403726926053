import React from 'react'
import styled from 'styled-components'

import { Editable } from 'components/blocks/deprecated/Editable'
import { Text } from 'components/ui/deprecated/Text'
import {
  CalculatorPricingListItemStoryblok,
  CalculatorPricingListStoryblok,
  PricingListItemValueStoryblok,
} from 'lib/storyblok/types'
import { addAlphaToColor, dashedLine } from 'lib/style'
import { formatPrice } from 'lib/utils/price'

import {
  PricingListHeader,
  PricingRowInfo,
  PricingRowValue,
} from './PricingList'

type Props = {
  data: CalculatorPricingListStoryblok
  currency: string
  locale: string
}

const PricingRow: React.FC<{
  name: string
  values: PricingListItemValueStoryblok[]
  locale: string
  currency: string
}> = ({ name, values, locale, currency }) => (
  <StyledPricingRow>
    {name && <PricingRowInfo name={name} />}

    <PricingRowValues>
      {values?.map((rowValue) => (
        <PricingRowValue
          key={rowValue._uid}
          rowValue={rowValue}
          locale={locale}
          currency={currency}
        />
      ))}
    </PricingRowValues>
  </StyledPricingRow>
)

const TotalRow: React.FC<{
  label: string
  rows: CalculatorPricingListItemStoryblok[]
  locale: string
  currency: string
}> = ({ label, rows, locale, currency }) => (
  <StyledTotalRow>
    <Text as="p" variant="twenty/regular" color="accent.secondary">
      {label}
    </Text>
    <PricingRowValues>
      {rows[0].values?.map((_, index) => {
        const value = rows.reduce(
          (total, row) => total + Number(row.values?.[index]?.value || 0),
          0
        )
        return (
          <TotalRowValue
            key={index}
            as="p"
            variant="twenty/regular"
            color="accent.secondary"
          >
            {formatPrice(locale, value, currency, 0, 0)}
          </TotalRowValue>
        )
      })}
    </PricingRowValues>
  </StyledTotalRow>
)

export const CalculatorPricingList: React.FC<Props> = ({
  data,
  currency,
  locale,
}) => {
  const { tag, description, rows, total_label } = data

  return (
    <Editable block={data} key={data._uid}>
      <Container>
        <PricingListHeader tag={tag} description={description} />

        <div>
          {rows?.length && (
            <PricingRows>
              {rows.map((row) => (
                <Editable block={row} key={row._uid}>
                  <PricingRow
                    name={row.name || ''}
                    values={row.values}
                    locale={locale}
                    currency={currency}
                  />
                </Editable>
              ))}
              <Border />
            </PricingRows>
          )}

          {rows?.length && total_label && (
            <TotalRow
              label={total_label}
              rows={rows}
              locale={locale}
              currency={currency}
            />
          )}
        </div>
      </Container>
    </Editable>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 1.25rem;
`

const PricingRows = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    gap: 0.375rem;
  }
`

const StyledPricingRow = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const PricingRowValues = styled.div`
  display: flex;
  gap: 0.5rem;

  ${({ theme }) => theme.media.lg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
`

const TotalRowValue = styled(Text)`
  color: ${({ theme }) => theme.colors.accent.secondary};

  display: flex;
  min-width: 68px;

  ${({ theme }) => theme.media.lg} {
    min-width: 78px;
  }
`

const Border = styled.div`
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.subtle, 40))};
`

const StyledTotalRow = styled(StyledPricingRow)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
`
