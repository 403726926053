import React from 'react'
import styled from 'styled-components'

import { Editable } from 'components/blocks/deprecated/Editable'
import { Text } from 'components/ui/deprecated/Text'
import { PricingSectionTabContentTableHeaderStoryblok } from 'lib/storyblok/types'
import { addAlphaToColor, dashedLine } from 'lib/style'

type Props = {
  header: PricingSectionTabContentTableHeaderStoryblok
}

export const PricingTableHeader: React.FC<Props> = ({ header }) => {
  const { currency_label, plans } = header

  return (
    <Editable block={header} key={header._uid}>
      <Container>
        {currency_label && (
          <CurrencyLabel as="p" variant="sixteen/regular">
            {currency_label}
          </CurrencyLabel>
        )}

        <HeaderPlans>
          {plans?.map((plan) => (
            <Editable block={plan} key={plan._uid}>
              <PlanContent key={plan._uid} as="p" variant="sixteen/regular">
                {plan.content}
              </PlanContent>
            </Editable>
          ))}
        </HeaderPlans>
        <Border />
      </Container>
    </Editable>
  )
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 1.25rem 0;
  background: ${({ theme }) => theme.colors.background.default};
  position: sticky;
  top: 3.9375rem;
  z-index: 40;

  ${({ theme }) => theme.media.md} {
    top: 3.4rem;
  }

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    top: 9.9375rem;
    padding-top: 0;
  }
`

const Border = styled.div`
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.subtle, 40))};
`

const HeaderPlans = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  ${({ theme }) => theme.media.lg} {
    gap: 2rem;
  }
`

const CurrencyLabel = styled(Text)``

const PlanContent = styled(Text)`
  display: flex;
  min-width: 68px;

  ${({ theme }) => theme.media.lg} {
    min-width: 78px;
  }
`
