import { useTranslation } from 'lib/i18n'

export const CURRENCY_SYMBOL: Record<string, string> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  PLN: 'zł',
} as const

export const formatPrice = (
  locale: string,
  price: number | string,
  currency: string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string => {
  if (isNaN(Number(price))) {
    switch (currency) {
      case 'PLN':
        return `${price} ${CURRENCY_SYMBOL[currency]}`
      default:
        return `${CURRENCY_SYMBOL[currency]} ${price}`
    }
  }

  switch (currency) {
    case 'PLN':
      return `${Number(price).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${CURRENCY_SYMBOL[currency]}`
    default:
      Number(price).toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
  }

  return Number(price).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  })
}

export const useLocalizedPriceFormat = (): ((
  price: number | string,
  currency: string
) => string) => {
  const { locale } = useTranslation()

  return formatPrice.bind(null, locale)
}

export const replaceCodeWithSymbol = (string: string): string => {
  const currencyCodes = Object.keys(CURRENCY_SYMBOL)
  const currencySymbols = Object.values(CURRENCY_SYMBOL)

  let convertedString = string
  for (let i = currencyCodes.length - 1; i >= 0; i--) {
    convertedString = convertedString.replace(
      RegExp(
        '\\b' +
          currencyCodes[i].replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') +
          '\\b',
        'g'
      ),
      currencySymbols[i]
    )
  }

  return convertedString
}
